@import "primeicons/primeicons.css";

.coverflowslide .slide {
  position: relative;
  transition: transform 0.3s;
}

.coverflowslide .slick-list {
  overflow: hidden !important;
}

.coverflowslide .slick-center {
  transform: scale(1.2); // Adjust the scale factor for the "Coverflow" effect
  z-index: 1;
}

.coverflowslide .carousel {
  width: 100%;
  max-width: 600px; // Adjust the carousel width as needed
  margin: 0 auto;
}

.space .slick-slide {
  margin: 10px;
}

[data-bs-theme="dark"] {
  .fc {
    .fc-multimonth-daygrid {
      background: #212529 !important;
    }

    .fc-day-disabled {
      background: #282b2e !important;
    }

    .fc-multimonth {
      border-color: #32383e !important;
    }
  }
}

#landingnft {
  .slick-dots {
    bottom: -36px;
    z-index: 1;

    .slick-active {
      button {
        background-color: #3577f1 !important;
      }
    }
  }

  .slick-dots li {
    height: 7px !important;
  }

  .slick-dots li button {
    width: 22px !important;
    height: 5px !important;
    border: none;
    background-color: rgba(109, 98, 253, 0.2) !important;
    padding: 0px;
    margin: 0px;
    transition: all 0.3s;
    border-radius: 50px;

    &::before {
      display: none;
    }
  }
}

.required-star {
  color: red;
}

.cus-form-sub-title {
  width: 100%;
  color: gray;
  font-size: 14px;
  font-weight: 500;
  border-bottom: rgba(128, 128, 128, 0.4) solid 1px;
  margin-bottom: 0.6rem;
}

.dz-progress {
  display: none !important;
}

.dz-remove {
  color: red !important;
}

.navbar-menu .navbar-nav .nav-link {
  margin: 0 10px 0 10px;
}

.navbar-menu
  .navbar-nav
  .nav-link.active[data-bs-toggle="collapse"][aria-expanded="true"] {
  background: #0a3d2c !important;
  color: #ffffff !important;
  border-radius: 7px;
  margin: 0 10px 0 10px;
  box-shadow: -3px 7px 31px -8px rgba(10, 61, 44, 0.52);
}

.navbar-menu .navbar-nav .nav-link.active {
  background: #0a3d2c !important;
  color: #ffffff !important;
  border-radius: 7px;
  margin: 0 10px 0 10px;
  box-shadow: -3px 7px 31px -8px rgba(10, 61, 44, 0.52);

  svg {
    color: white !important;
  }
}

.navbar-menu {
  background-color: #fefefe !important;
  box-shadow: 5px 1px 18px -10px rgba(0, 0, 0, 0.2) !important;
  position: fixed !important;
}

.navbar-menu
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]
  .icon-dual
  svg {
  color: white !important;
}

/* custom scss for nav bar link */
.navbar-menu .navbar-nav .nav-link {
  padding: 10px 15px 11px !important;
  /* padding: 0.62rem 1.5rem; */
}

/* y-scroll bar  */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #afb1b1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #d2d8dd;
  border-radius: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #dee2e6;
}

/* x scroll bar */
::-webkit-scrollbar:horizontal {
  height: 8px; /* Adjust height for horizontal scrollbar */
}

::-webkit-scrollbar-track:horizontal {
  box-shadow: none 0 0 5px #0a3d2c;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #d2d8dd;
  border-radius: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #dee2e6;
}

/* x mark */
::ng-deep .ng-select .ng-clear-wrapper {
  padding-top: 4px !important;
}
/* delete button */

/* ::ng-deep .ngx-overlay {
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px);
}
 */

/* loader icon */
::ng-deep .ngx-ui-loader-foreground-spinner {
  transform: scaleX(
    1.5
  ) !important; /* Adjust the scale factor to increase width */
  //width: 250px !important;
}
