//
// _authentication.scss
//

.fgt-password-text:hover {
  color: $success !important;
  font-weight: 400;
}

.auth-page-wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  .login-side-page {
    background: #ffffff;
    // height: 100vh;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .login-wrapper {
      width: 100%;
      padding: 0 5rem;

      @media (max-width: 425px) {
        padding: 0 2rem;
      }
    }
  }

  .auth-page-content {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .footer {
    left: 0;
    background-color: transparent;
    color: var(--#{$prefix}body-color);
  }
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  backdrop-filter: blur(5px); /* Blur effect */
  border-radius: 10px; /* Border radius for the card */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Optional: Border to enhance the effect */
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37); /* Box shadow for depth */
}

.auth-one-bg-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 575.98px) {
    height: 280px;
  }
}

.auth-one-bg {
  background-image: url("../../images/cgl/bg/bg-Login.jpg");
  background-position: center;
  background-size: cover;

  .bg-overlay {
    background: linear-gradient(to right, darken(#000, 5%), #000);
    opacity: 0.2;
  }
}

.shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  > svg {
    width: 100%;
    height: auto;
    fill: var(--#{$prefix}body-bg);
  }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ecb6";
    }
  }
}

.particles-js-canvas-el {
  position: relative;
}

// signin card title

.signin-other-title {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    border-top: 1px dashed var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: var(--#{$prefix}secondary-bg);
    padding: 2px 16px;
  }
}

// Auth cover

.auth-bg-cover {
  background: $auth-bg-cover;

  > .bg-overlay {
    background-image: url("../../images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba($white, 0.5);
  }
}

//passowrd validations
#password-contain {
  display: none;

  p {
    padding-left: 13px;

    &.valid {
      color: $success;

      &::before {
        position: relative;
        left: -8px;
        content: "✔";
      }
    }

    &.invalid {
      color: $danger;

      &::before {
        position: relative;
        left: -8px;
        content: "✖";
      }
    }
  }
}
